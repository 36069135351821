@import './fonts.css';
@import './utilities.css';
@import './no-scrollbar.css';
@import './notificationBanner.css';
@import './progress-bar.module.css';
@import './multiline-ellipsis.css';
@import './react-slick.module.css';
@import './article.css';
@import './article-editor.css';
@import './article-settings.css';
@import './no-input-arrows.css';
@import '../components/Highlight/highlight.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  @apply bg-white-100;
  @apply font-secondary;
}
